import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Guess Who?": "Guess Who?",
      "Enter your name": "Enter your name",
      "Start Game": "Start Game",
      "Top Scores": "Top Scores",
      "Select a character": "Select a character",
      "Guess": "Guess",
      "Correct! The character is": "Correct! The character is",
      "Wrong guess. Try again.": "Wrong guess. Try again.",
      "Time": "Time",
      "Questions Asked": "Questions Asked",
      "Questions": "Questions",
      "Time (s)": "Time (s)",
      "No scores yet": "No scores yet",
      "John": "John",
      "Jane": "Jane",
      "Joe": "Joe",
      "Jill": "Jill",
      "Mike": "Mike",
      "Ann": "Ann",
      "Frank": "Frank",
      "Linda": "Linda",
      "Tom": "Tom",
      "Lucy": "Lucy",
      "Paul": "Paul",
      "Does the character have glasses?": "Does the character have glasses?",
      "Is the character wearing a hat?": "Is the character wearing a hat?",
      "Is the character male?": "Is the character male?",
      "Is the character female?": "Is the character female?",
      "Is the character's shirt color blue?": "Is the character's shirt color blue?",
      "Is the character's shirt color red?": "Is the character's shirt color red?",
      "Is the character's hair color brown?": "Is the character's hair color brown?",
      "Is the character's hair color blonde?": "Is the character's hair color blonde?",
      "Is the character's skin color light?": "Is the character's skin color light?",
      "Is the character's skin color dark?": "Is the character's skin color dark?"
    }
  },
  he: {
    translation: {
      "Guess Who?": "נחש מי?",
      "Enter your name": "הכנס את שמך",
      "Start Game": "התחל משחק",
      "Top Scores": "ציונים מובילים",
      "Select a character": "בחר דמות",
      "Guess": "נחש",
      "Correct! The character is": "נכון! הדמות היא",
      "Wrong guess. Try again.": "ניחוש שגוי. נסה שוב.",
      "Time": "זמן",
      "Questions Asked": "מספר שאלות",
      "Questions": "שאלות",
      "Time (s)": "זמן (שניות)",
      "No scores yet": "אין ציונים עדיין",
      "John": "ג'ון",
      "Jane": "ג'יין",
      "Joe": "ג'ו",
      "Jill": "ג'יל",
      "Mike": "מייק",
      "Ann": "אן",
      "Frank": "פרנק",
      "Linda": "לינדה",
      "Tom": "טום",
      "Lucy": "לוסי",
      "Paul": "פול",
      "Does the character have glasses?": "האם לדמות יש משקפיים?",
      "Is the character wearing a hat?": "האם הדמות חובשת כובע?",
      "Is the character male?": "האם הדמות זכר?",
      "Is the character female?": "האם הדמות נקבה?",
      "Is the character's shirt color blue?": "האם צבע החולצה של הדמות כחול?",
      "Is the character's shirt color red?": "האם צבע החולצה של הדמות אדום?",
      "Is the character's hair color brown?": "האם צבע השיער של הדמות חום?",
      "Is the character's hair color blonde?": "האם צבע השיער של הדמות בלונד?",
      "Is the character's skin color light?": "האם צבע העור של הדמות בהיר?",
      "Is the character's skin color dark?": "האם צבע העור של הדמות כהה?"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
