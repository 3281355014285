export const getScores = async () => {
  try {
    const response = await fetch('http://localhost:3001/scores');
    const scores = await response.json();
    return scores;
  } catch (error) {
    console.error('Error fetching scores:', error);
    return [];
  }
};

export const saveScore = async (newScore) => {
  try {
    const response = await fetch('http://localhost:3001/scores', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newScore),
    });
    return await response.json();
  } catch (error) {
    console.error('Error saving score:', error);
  }
};
