import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import characters from '../data/characters';
import QuestionForm from './QuestionForm';
import { saveScore, getScores } from '../data/scores';
import i18n from '../i18n';

const CharacterCard = ({ character }) => {
  const { i18n } = useTranslation();
  return (
    <div className="character-card">
      <img src={character.image} alt={character.name[i18n.language]} />
      <p>{character.name[i18n.language]}</p>
    </div>
  );
};

const GameBoard = () => {
  const { t, i18n } = useTranslation();
  const [remainingCharacters, setRemainingCharacters] = useState(characters);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [scores, setScores] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [time, setTime] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchScores = async () => {
      const scores = await getScores();
      setScores(scores);
    };

    fetchScores();
  }, []);

  useEffect(() => {
    if (selectedCharacter) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      setTime(0);
    }
  }, [selectedCharacter]);

  const handleQuestion = (question) => {
    if (!selectedCharacter) return;
    const isTrue = question.answer(selectedCharacter);
    setRemainingCharacters(remainingCharacters.filter(character => question.answer(character) === isTrue));
    setQuestionCount(prevCount => prevCount + 1);
  };

  const startGame = () => {
    const randomCharacter = characters[Math.floor(Math.random() * characters.length)];
    setSelectedCharacter(randomCharacter);
    setRemainingCharacters(characters);
    setGuess('');
    setMessage('');
    setQuestionCount(0);
    setTime(0);
  };

  const handleGuessChange = (e) => {
    setGuess(e.target.value);
  };

  const handleGuessSubmit = async (e) => {
    e.preventDefault();
    if (guess === selectedCharacter.name[i18n.language]) {
      clearInterval(intervalRef.current); // Stop the timer
      setMessage(`${t('Correct! The character is')} ${selectedCharacter.name[i18n.language]}.`);
      const newScore = { name: playerName, date: new Date().toLocaleString(), character: selectedCharacter.name[i18n.language], questions: questionCount, time };
      await saveScore(newScore);
      const updatedScores = await getScores();
      setScores(updatedScores);
    } else {
      setMessage(t('Wrong guess. Try again.'));
    }
  };

  const handleNameChange = (e) => {
    setPlayerName(e.target.value);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="container">
      <h1>{t('Guess Who?')}</h1>
      <div className="language-buttons">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('he')}>עברית</button>
      </div>
      {!selectedCharacter ? (
        <>
          <input
            type="text"
            value={playerName}
            onChange={handleNameChange}
            placeholder={t('Enter your name')}
          />
          <button onClick={startGame} disabled={!playerName}>{t('Start Game')}</button>
          <div className="top-scores">
            <h2>{t('Top Scores')}</h2>
            {scores.length > 0 ? (
              <ul>
                {scores.map((score, index) => (
                  <li key={index}>
                    <div>
                      <strong>{score.name}</strong> - {score.character}
                    </div>
                    <div className="score-info">
                      {t('Questions')}: {score.questions} - {t('Time (s)')}: {score.time}
                    </div>
                    <div className="score-date">
                      {score.date}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('No scores yet')}</p>
            )}
          </div>
        </>
      ) : (
        <>
          <QuestionForm handleQuestion={handleQuestion} />
          <form onSubmit={handleGuessSubmit}>
            <select value={guess} onChange={handleGuessChange}>
              <option value="">{t('Select a character')}</option>
              {characters.map((character) => (
                <option key={character.id} value={character.name[i18n.language]}>
                  {character.name[i18n.language]}
                </option>
              ))}
            </select>
            <button type="submit">{t('Guess')}</button>
          </form>
          {message && <p>{message}</p>}
          <div className="character-grid">
            {remainingCharacters.map((character) => (
              <CharacterCard key={character.id} character={character} />
            ))}
          </div>
          <p>{t('Time')}: {time}s</p>
          <p>{t('Questions Asked')}: {questionCount}</p>
        </>
      )}
    </div>
  );
};

export default GameBoard;
