const questions = [
  {
    id: 1,
    text: { en: "Does the character have glasses?", he: "האם לדמות יש משקפיים?" },
    answer: character => character.glasses
  },
  {
    id: 2,
    text: { en: "Is the character wearing a hat?", he: "האם הדמות חובשת כובע?" },
    answer: character => character.hat
  },
  {
    id: 3,
    text: { en: "Is the character male?", he: "האם הדמות זכר?" },
    answer: character => character.gender === "male"
  },
  {
    id: 4,
    text: { en: "Is the character female?", he: "האם הדמות נקבה?" },
    answer: character => character.gender === "female"
  },
  {
    id: 5,
    text: { en: "Is the character's shirt color blue?", he: "האם צבע החולצה של הדמות כחול?" },
    answer: character => character.shirtColor === "blue"
  },
  {
    id: 6,
    text: { en: "Is the character's shirt color red?", he: "האם צבע החולצה של הדמות אדום?" },
    answer: character => character.shirtColor === "red"
  },
  {
    id: 7,
    text: { en: "Is the character's hair color brown?", he: "האם צבע השיער של הדמות חום?" },
    answer: character => character.hairColor === "brown"
  },
  {
    id: 8,
    text: { en: "Is the character's hair color blonde?", he: "האם צבע השיער של הדמות בלונד?" },
    answer: character => character.hairColor === "blonde"
  },
  {
    id: 9,
    text: { en: "Is the character's skin color light?", he: "האם צבע העור של הדמות בהיר?" },
    answer: character => character.skinColor === "light"
  },
  {
    id: 10,
    text: { en: "Is the character's skin color dark?", he: "האם צבע העור של הדמות כהה?" },
    answer: character => character.skinColor === "dark"
  }
];

export default questions;
