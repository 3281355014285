import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import questions from '../data/questions';

const QuestionForm = ({ handleQuestion }) => {
  const { t, i18n } = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState('');

  const handleChange = (e) => {
    setSelectedQuestion(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const question = questions.find(q => q.text[i18n.language] === selectedQuestion);
    handleQuestion(question);
    setSelectedQuestion('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <select value={selectedQuestion} onChange={handleChange}>
        <option value="">{t('Select a question')}</option>
        {questions.map((question) => (
          <option key={question.id} value={question.text[i18n.language]}>
            {question.text[i18n.language]}
          </option>
        ))}
      </select>
      <button type="submit">{t('Ask')}</button>
    </form>
  );
};

export default QuestionForm;
