const characters = [
  {
    id: 1,
    name: { en: "John", he: "יוחנן" },
    image: "/images/john.png",
    glasses: true,
    hat: true,
    gender: "male",
    shirtColor: "light blue",
    hairColor: "brown",
    skinColor: "light",
  },
  {
    id: 2,
    name: { en: "Jane", he: "ג'יין" },
    image: "/images/jane.png",
    glasses: true,
    hat: true,
    gender: "female",
    shirtColor: "red",
    hairColor: "black",
    skinColor: "light",
  },
  {
    id: 3,
    name: { en: "Joe", he: "יוסי" },
    image: "/images/joe.png",
    glasses: true,
    hat: true,
    gender: "male",
    shirtColor: "green",
    hairColor: "brown",
    // hairColor: "blonde",
    skinColor: "light",
  },
  {
    id: 4,
    name: { en: "Jill", he: "ג'יל" },
    image: "/images/jill.png",
    glasses: true,
    hat: true,
    gender: "female",
    shirtColor: "orange",
    hairColor: "brown",
    skinColor: "light",
  },
  {
    id: 5,
    name: { en: "Mike", he: "מיכאל" },
    image: "/images/mike.png",
    glasses: true,
    hat: true,
    gender: "male",
    shirtColor: "light blue",
    hairColor: "brown",
    skinColor: "light",
    // skinColor: "dark",
  },
  {
    id: 6,
    name: { en: "Ann", he: "חנה" },
    image: "/images/ann.png",
    glasses: true,
    hat: false,
    gender: "female",
    shirtColor: "yellow",
    hairColor: "black",
    skinColor: "light",
  },
  {
    id: 7,
    name: { en: "Frank", he: "פרנק" },
    image: "/images/frank.png",
    glasses: true,
    hat: true,
    gender: "male",
    shirtColor: "purple",
    hairColor: "brown",
    skinColor: "dark",
  },
  {
    id: 8,
    name: { en: "Linda", he: "לינדה" },
    image: "/images/linda.png",
    glasses: true,
    hat: false,
    gender: "female",
    shirtColor: "green",
    hairColor: "blonde",
    skinColor: "light",
  },
  {
    id: 9,
    name: { en: "Tom", he: "תום" },
    image: "/images/tom.png",
    glasses: true,
    hat: false,
    gender: "male",
    shirtColor: "black",
    hairColor: "black",
    skinColor: "dark",
  },
  {
    id: 10,
    name: { en: "Lucy", he: "לוסי" },
    image: "/images/lucy.png",
    glasses: true,
    hat: false,
    gender: "female",
    shirtColor: "pink",
    hairColor: "red",
    skinColor: "light",
  },
  {
    id: 11,
    name: { en: "Paul", he: "פאול" },
    image: "/images/paul.png",
    glasses: true,
    hat: true,
    gender: "male",
    shirtColor: "white",
    hairColor: "brown",
    skinColor: "dark",
  },
];

export default characters;

//   const characters = [
//     { id: 1, name: 'John', image: 'https://via.placeholder.com/150', glasses: true, hat: false, gender: 'male' },
//     { id: 2, name: 'Jane', image: 'https://via.placeholder.com/150', glasses: false, hat: true, gender: 'female' },
//     { id: 3, name: 'Joe', image: 'https://via.placeholder.com/150', glasses: true, hat: true, gender: 'male' },
//     { id: 4, name: 'Jill', image: 'https://via.placeholder.com/150', glasses: false, hat: false, gender: 'female' },
//   ];

//   export default characters;
